import axios from 'axios'
import { API_URL } from '../../utils/ApiUrl';


export const GetUsers = (account) => async (dispatch) => {
  // dispatch({
  //   type: "loader",
  //   payload: true,
  // });

  if(account){
    // console.log("accccc in reduc",account)
    await axios.post(API_URL + "users/getUser", { walletAddress: account })
    .then(async (res) => {
      if (res.data.user != null ) {
        // loader=false
        // console.log("iff  s",res.data.user)
        dispatch({
          type: "GETUSER",
          payload: res.data.user,
        });
        // dispatch({
        //   type: "loader",
        //   payload: false,
        // });
      }else{
        // console.log('else')
        // loader=false
        dispatch({
          type: "GETUSER",
          payload: '',
        });
        // dispatch({
        //   type: "loader",
        //   payload: false,
        // });
      }
    })
    .catch((err) => {
      // loader=false
      console.log("errrrrrrr,",err)
      return false;
    })
  }

};

export const hideLoader = () => (dispatch) => {
  dispatch({
    type: 'HIDE_LOADER',
  });
};

// export const ContarctAction = (Useraccount, txiContract,amount,recipt) => async (
//     dispatch
//   ) => {
//      txiContract.methods
//       .balanceOf(Useraccount)
//       .call()
//       .then((balance) => { 
//         // console.log("balance",balance);    
//         dispatch({
//           type: "BALANCE",
//           payload: balance,
//         });
//       });
//       txiContract.methods
//       .calculateBNBReward(Useraccount)
//       .call()
//       .then((reward) => { 
//         // console.log("reward",reward);    
//         dispatch({
//           type: "USER_REWARD",
//           payload: reward,
//         });
//       });

//       // txiContract.methods.disruptiveTransfer(amount,recipt).send(
//       //   {
//       //   from: Useraccount,
//       //   amount,
//       //   recipt
//       // }).then((amount)=>{
//       //   dispatch({
//       //     type: "",
//       //     payload: amount,
//       //   })
//       // })
        
//   };
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './footer.scss';
import { HashLink } from 'react-router-hash-link';
const Footer = () => {
    return (
        <>
            <section className="main-footer ">
                <div className="containe-fluid">
                    <div className='row '>
                        <div className='col-xl-12 col-lg-12  col-sm-11   mx-auto main_fotrdiv'>
                            <div className="row my-footer-border">
                                <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 m-auto  col-12 ">
                                    <div className="inner-logo">
                                        <a className="" href="/">
                                            <img src="\DNS-logo.svg" className="img-fluid w200 " alt="" />
                                        </a>
                                        {/* <h6 className=' dignissimos '>Omnis iste natus error sit voluptatem accusantium doloremque laudant totam rem aperiam, eaque ipsa quae ab illo.</h6> */}
                                        <ul className='list-inline ml-3'>
                                            <li className='list-inline-item'>
                                                <a className=''>
                                                    {/* <img src="\Facebook.svg" className="img-fluid w28" alt="" /> */}
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a className=''>
                                                    {/* <img src="\Instagram.svg" className="img-fluid w28" alt="" /> */}
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a href="https://twitter.com/ShibCupidai" target="_blank">
                                                    {/* <img src="\Twitter.svg" className="img-fluid w28" alt="" /> */}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-4 col-md-6 col-sm-12  m-auto padng_left'>
                                    <div className='padng_lefts '>
                                        <h4 className='headdiinnnggssss'>Quick Links</h4>
                                        <ul>
                                            <li className="nav-item">

                                                <Link to="/main-banner">
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <HashLink to="#Thrddsection" style={
                                                    ("#about")
                                                        ? {
                                                            color: "#", borderBottom: ""
                                                        }
                                                        : {}}>Tokenomics</HashLink>
                                            </li>
                                            <li>

                                                <HashLink to="#Roadmap"
                                                >Roadmap</HashLink>
                                            </li>
                                            {/* <li>
                                                <div className=''>
                                                    <Link to="/">
                                                        <a className='white footerhead'>FAQs</a>

                                                    </Link>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-1  m-auto padng_left ddddnone'>
                                    <div className='certerbdr'>

                                    </div>

                                </div>

                                <div className='col-xl-2 col-lg-4 col-md-6 col-sm-12 m-auto  padng_left'>
                                    <div className='padng_left '>
                                        <h4 className='headdiinnnggssss'>Company</h4>
                                        <ul>
                                            <li>
                                                <div className=''>
                                                    <HashLink to="#secbannars yoyonewwwwwww"> about</HashLink>
                                                </div>
                                            </li>
                                            <li>
                                                <div className=''>
                                                    <li className="nav-item ">
                                                        <HashLink to="#Adress"> Team</HashLink>
                                                    </li>
                                                </div>
                                            </li>
                                            <li>
                                                {/* <div className=''>
                                                    <a className='yoyonewwwwwww'>FAQs</a>
                                                </div> */}
                                                <li className="nav-item yoyonewwwwwww">

                                                <a href="https://t.me/cupid_AI" target="_blank">
                    Community

                    </a>
                                                </li>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className='col-1  m-auto  padng_left ddddnone'>
                                    <div className='certerbdr'>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 m-auto ">
                                    <div className="inner-icon ">
                                        <h4 className='headdiinnnggssss'>Sign up to get the latest news.</h4>

                                        <ul className='list-inline pr-2'>
                                            <li className='list-inline-item'>
                                                <p className='yoyonewwwwwwww '>E-Mail Address </p>
                                            </li>

                                            <li className='list-inline-item'>
                                                <a href="https://twitter.com/ShibCupidai" target="_blank">
                                                    <img src="\Twitter.svg" className="img-fluid w28 pl-1" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <div className="input-group-append picture">
                                                    <input type="text" className="form-control" placeholder="Enter your email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                </div>
                                                <div className="input-group-append picture">
                                                    <button className='lastfotrbtn'>

                                                        Submit
                                                    </button>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="rights ">
                <div className='containe-fluid'>
                    <div className="row">
                        <div className="col-xl-11 col-lg-12 col-12   m-auto  ptb20">
                            <div className='row'>
                                <div className="col-sm-6 m-auto">
                                    <p className="text-dark small cccopyrighttttts foterfst ">© 2023 Cupidai.io, all rights reserved.</p>
                                </div>
                                <div className="col-sm-6 m-auto ">
                                    <p className="text-dark small cccopyrighttttts fotersec">     <li className='list-inline-item'>
                                        

                                        {/*  <a className=''>
                                            <img src="\fotrlogo.png" className="img-fluid w28 pl-2" alt="" />
                                        </a> */}
                                    </li>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

        </>
    )
}

export default Footer;

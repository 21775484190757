import React, { useEffect } from "react";
import "./adress.scss"
import AOS from "aos";
import "aos/dist/aos.css";
export const Adress = () => {
    useEffect(() => {
      AOS.init();
    }, []);
    return (
        <>
            <section className="main-adress " id="Adress">
                <div className='container-fluid '>
                    <div className="row">
                        <div className="col-xl-11 col-11 m-auto p-0 padd-sm">
                            <div className='row'>
                                <div className='col-sm-8 mx-auto'>
                                    <div className='heading'>
                                        <h2 className='ggeetttt'>Our Cupids</h2>
                                        <p className='misskar'> Cupid.Ai is a revolutionary platform that aims to democratize the creation of decentralized applications (dApps) in the Web3 ecosystem.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12 m-auto  '>
                                    <div className="all-cards bgorange" data-aos="fade-right">
                                        <div className="main-card">
                                            <img src="\img (1).svg" alt="img" className='img-fluid' />
                                            <h6>Cameron Douglas</h6>
                                            <p>Business Analytic</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12  padd-sm'>
                                    <div className="all-cards bgorange" data-aos="fade-left">
                                        <div className="main-card">
                                            <img src="\img (2).svg" alt="img" className='img-fluid' />
                                            <h6>Damon Garza </h6>
                                            <p>Branding Designer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12  '>
                                    <div className="all-cards bgorange" data-aos="fade-right">
                                        <div className="main-card">
                                            <img src="\img (3).svg" alt="img" className='img-fluid' />
                                            <h6>Jaxson Larson </h6>
                                            <p>Program Supervisor </p>
                                                                               </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12  padd-sm'>
                                    <div className="all-cards bgorange" data-aos="fade-left">
                                        <div className="main-card">
                                            <img src="\img (4).svg" alt="img" className='img-fluid' />
                                            <h6>Jonty Horne</h6>
                                       
                                            <p>Integration Supervisor</p>
                                     
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
export default Adress;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
export const Lastvideo = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <section className="main-lastvideo " id="Lastvideo">
            <div className='container-fluid '>
                <div className="row">
                    <div className="col-xl-11 col-11 m-auto p-0 padd-sm">
                    <div className="inner-content main-heading text-center  " data-aos="fade-down"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000">
                    <img
                      src="\videobanannnaa.svg"
                      alt="img"
                      className="img-fluid header_imgs "

                    />
                        {/* <video width="100%" height="auto"  controls autoplay>
                      <source src="\Welcome To CUPID AI.mp4" type="video/mp4" />
                      <source src="movie.ogg" type="video/ogg" />
              
                    </video> */}
         
                  </div>


                    </div>
                </div>
            </div>

        </section>
    );
}

export default Lastvideo;

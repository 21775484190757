import { useEffect } from 'react';
import './parallexcard.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Parallexcards = () => {

    const options = {
        responsiveClass: true,
        nav: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
                loop: true,
                margin: 15
            },
            500: {
                items: 1.5,
                loop: true,
                margin: 35
            },
            600: {
                items: 2,
                margin: 40
            },
            700: {
                items: 2,
                margin: 40
            },
            1050: {
                items: 3,
                margin: 40

            },
            1200: {
                items: 3.5,
                margin: 40

            },
            1450: {
                items: 3.5,
                margin: 40

            },


        },
    };


    return (

        <section className='paralleexx_carrds'>
            <div className='koibee'>



                <div class="timeline__sections  ">
             


                        <OwlCarousel className='owl-theme' {...options} loop autoplay autoplaySpeed={5000} autoplayTimeout={5000} margin={40} nav>
                            <section className='mysection'>
                                <div className='roadmap_cards'>
                                    <p className='card_date'>
                                        2023
                                    </p>
                                    <h3 className='quarter'>
                                        quarter 01
                                    </h3>
                                    <ul class="roadmap_list">
                                        <li > <p className='lissrow'>
                                            Vision was Created
                                        </p> </li>
                                        <li>Cupid website Launch</li>
                                        <li>Cupid Token Launch</li>
                                        <li>Presale Round </li>
                                    </ul>
                                </div>
                            </section>


                            <section className='mysectiondd'>
                                <div className='roadmap_cards'>
                                    <p className='card_date'>
                                        2023
                                    </p>
                                    <h3 className='quarter'>
                                        quarter 02
                                    </h3>
                                    <ul class="roadmap_list">
                                        <li>Listing on exchange </li>
                                        <li> Launch 1st Domain name service on shibarium Network</li>
                                        <li> Launch auction mechanism of DNS.</li>
                                        {/* <li> </li> */}
                                    </ul>
                                </div>
                            </section>


                            <section className='mysectiondd'>
                                <div className='roadmap_cards'>
                                    <p className='card_date'>
                                        2023
                                    </p>
                                    <h3 className='quarter'>
                                        quarter 03
                                    </h3>
                                    <ul class="roadmap_list">
                                        <li>Launch 1st NFT marketplace on shibarium Network </li>
                                        <li> Launch 1st Shibarium Generative NFTs on shibarium Network</li>
                                        <li> Launch 1st DEX on shibarium Network</li>
                                        {/* <li> </li> */}
                                    </ul>
                                </div>
                            </section>


                            <section className='mysectiondd'>
                                <div className='roadmap_cards'>
                                    <p className='card_date'>
                                        2023
                                    </p>
                                    <h3 className='quarter'>
                                        quarter 04
                                    </h3>
                                    <ul class="roadmap_list">
                                        <li>Launch 1st Decentralized Wallet on shibarium Network </li>
                                        <li>Launch 1st DAO on shibarium Network</li>
                                        <li>Launch 1st Launchpad on shibarium Network</li>
                                        {/* <li></li> */}
                                    </ul>
                                </div>
                            </section>
                        </OwlCarousel>
                    </div>
                </div>
 










        </section>




    )
}

export default Parallexcards




import ReactApexChart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import "./piechart.scss"
function Piechart() {


  let a = [50, 40, 30, 0]
  const state = {
    series: [100,],
    options: {
      chart: {
        type: 'donut',
        foreColor: '#FCA500'
      },
      stroke: {
        show: false,
        width: 0
      },
      grid: {
        borderColor: "red"
      },
      colors: ['       #FCA500 ', '#FF90EA', '#3878F4', '#9E70EA'],
      labels: ['100% Liquidity Pool',],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          },
        }
      }],
    }

  };
  //   useEffect(() => {
  //     graphTimer()
  //   }, []);
  return (
    <div id="chart" className="stabl3graph treasure-graph">
      {/* <ReactApexChart options={options} series={series} type="line" height={350} /> */}
      <ReactApexChart options={state.options} series={state.series} type="donut" height={408} />
    </div>

  );
}
export default Piechart;


import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { API_URL } from "../../../utils/ApiUrl";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import useEthBalance from "../../../hooks/userBalance";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
const Copied = styled("span")`
  font-size: 16px;
  margin-left: 10px;
`;
const HOVER_TIMEOUT = 1000;

const Navbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggleBtn, setToggleBtn] = useState(false)
  console.log("🚀 ~ file: Navbar.js ~ line 21 ~ Navbar ~ toggleBtn", toggleBtn)

  const users = useSelector((state) => state.UserReducer.GetUserAll);
  // console.log("user data", users);
  const { account } = useWeb3React();
  const [inputs, setInputs] = useState();
  const [copy, setCopy] = useState(false);
  // const [open, setOpen] = useState(false);
  const { login, logout } = useAuth();

  let ethBalance = useEthBalance();
  // console.log("ethBalance", ethBalance.toNumber() / 10**18)
  const balance = (ethBalance.toNumber() / 10 ** 18).toFixed(3)
  // console.log("balance of user", balance);

  const connectMetamask = () => {
    localStorage.setItem("connectorId", "injected");
    login("injected");
    localStorage.setItem("flag", "true");
    window.$("#exampleModal").modal("hide");
  };

  const trustWallet = async () => {
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout();
    } else {
      login("walletconnect");
      window.$("#exampleModal").modal("hide");
    }
  };

  const logout1 = () => {
    localStorage.setItem("flag", "false");
    // localStorage.clear();
    logout();
  };

  const [showsidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  return (
    <section className="main-navbar-mobile main-navbar">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-xl-11 col-lg-12 col-12  m-auto   nav_boder ">
            <nav className="navbar  navbar-expand-xl p-0">
              <ul className="navbar-nav m-auto razakiNavbar">
                <li class="nav-item active">
                  <HashLink to="#navbar" style={
                    isActive("")
                      ? {
                        color: "#", borderBottom: ""
                      }
                      : {}}>Home</HashLink>
                </li>
                <li className="nav-item ">
                  <HashLink to="#secbannars"> about</HashLink>
                </li>
                <li className="nav-item">

                  <HashLink to="#Thrddsection" style={
                    isActive("#about")
                      ? {
                        color: "#", borderBottom: ""
                      }
                      : {}}>Tokenomics</HashLink>
                </li>
              </ul>








              <div className="tooggllee d-none">
                <a href="#" className="navbar-brand " >
                  <Link to="/">
                    <img
                      src="\DNS-logo-nav.svg"
                      alt="img"
                      className="img-fluid  toogleimgg "
                    />
                  </Link>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={handleShowSidebar}
                >
                  <span class="navbar-toggler-icon "></span>
                </button>
              </div>


              <div
                className="collapse navbar-collapse marg"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav m-auto">

                  <a href="#" className="navbar-brand " >
                    <Link to="">
                      <img
                        src="\DNS-logo-nav.svg"
                        alt="img"
                        className="img-fluid "
                      />
                    </Link>
                  </a>
                  {/* <li className="nav-item active">
                    <HashLink to="#roadmap" style={
                      isActive("#roadmap")
                        ? {
                          color: "#", borderBottom: "2px solid #"
                        }
                        : {}}> RoadMap</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="#faqs" style={
                      isActive("#faqs")
                        ? {
                          color: "#1777e2", borderBottom: "2px solid #1777e2"
                        }
                        : {}}> FAQs</HashLink>
                  </li> */}


                </ul>
                {account ? (
                  <button
                    class="btn button-hedaer"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    type="button"
                  >
                    Connected
                  </button>
                ) : (
                  <ul className="navbar-nav ">
                    <li class="nav-item active">
                      <HashLink to="#Roadmap" style={
                        isActive("")
                          ? {
                            color: "#", borderBottom: "  #"
                          }
                          : {}}>Roadmap</HashLink>
                    </li>
                    <li className="nav-item ">
                      <HashLink to="#Adress"> Team</HashLink>
                    </li>
                    <li className="nav-item">
                      <a href="https://t.me/cupid_AI" target="_blank">
                        Community

                      </a>

                    </li>
                  </ul>
                )}


              </div>
            </nav>
          </div>
        </div>

      </div>



      <Offcanvas placement="top" className='mobile_sidebar' show={showsidebar} onHide={handleCloseSidebar}>
        <Offcanvas.Header closeButton>
          <div className="top-content">
            <a href="#" className="navbar-brand ">
              <Link to="/">
                <img
                  src="\DNS-logo-nav.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Link>
            </a>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="navbar-nav mr-auto">
            <li class="nav-item px-0 ">
              {/* <Link to="/Banner">Home</Link> */}
              <HashLink to="/Banner">Home</HashLink>
            </li>
            <li className="nav-item px-0 ">

            <HashLink to="#secbannars"> about</HashLink>



            </li>
            <li className="nav-item px-0 ">
              <a href="/Thrddsection" target="_blank">
                Tokenomics

              </a>
            </li>
            <li className="nav-item px-0 ">
              <a href="/Roadmap" target="_blank">
                Roadmap

              </a>

            </li>
            <li className="nav-item px-0 ">
              <a href="/Adress" target="_blank">
                Team

              </a>

            </li>
            <li className="nav-item px-0 ">
              <a href="https://t.me/cupid_AI" target="_blank">
                Community

              </a>
            </li>




          </ul>

        </Offcanvas.Body>
      </Offcanvas>

    </section>
  );
};

export default Navbar;

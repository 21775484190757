import { useEffect } from 'react';
import './roadmap.scss';
import AOS from "aos";
import "aos/dist/aos.css";
import Parallexcards from '../Paralexcard/Parallexcards';
export const Roadmap = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="roadmap" id='Roadmap' >
       <div class="container timeline">
          <div className="row">
            <div className=" col-12 col-xl-6 col-md-10 p-0 m-auto">
              <h1 class="timeline__heading " data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500">Cupid.Ai Roadmap</h1>
              <p className='inventorexx'> Cupid.Ai is a revolutionary platform that aims to democratize the creation of decentralized applications (dApps) in the Web3 ecosystem.</p>
            </div>
          </div>
            </div>
        <Parallexcards/>
          </section>
    </>
  );
};
export default Roadmap;

import React, { useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// import 'animate.css';
import AOS from "aos";
import "aos/dist/aos.css";
import Secbannars from "../Secndbannar/Secbannars";
const Banner = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="main-banner " id="Banner" >
        {/* <img src="\banarbg.svg" alt="img" className="img-fluid bg-banner" /> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11 col-lg-12 col-12 p-0 m-auto padd-sm">
              <div className="row">
                <div className="col-xl-6 col-md-10 m-auto col-12 ">
                  <div className="inner-content main-heading  " data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="1000">
                    <h2 className="parafirstcc " >1st love token that's will be launching on Shibarium Chain</h2>
                    <p className="parafirstff">A High quality, AI service provider building the Wix/Wordpress of Web3 and a range of many other tools powered by Artificial Intelligence for the Ethereum and Shibarium Network!
                      A 0% Tax coin on the Ethereum network. (Coming to Shibarium Soon!)</p>
                    <div className="lastbtnbnr ">
                      <a href="/" target="">
                        <button
                          className="btn button-hedaerss">
                          Buy $CUPID
                        </button>  </a>
                      <a href="\Cupid Ai Whitepaper.pdf" target="_blank">
                        <button
                          className="btn button-hedaersss">
                          Learn More
                        </button>  </a>
                      <button
                        className="btn "
                      >
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-12  m-auto padd-sm">
                  <div className="inner-content main-heading text-center  " data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000">
                    {/* <img
                      src="\banarsideimg.svg"
                      alt="img"
                      className="img-fluid header_imgs "

                    /> */}

                    {/* <video id="myVideo"
                      className=" banervideo"
                      muted="muted"
                      playsinline="playsinline"
                      autoPlay
                      loop
                      width="100%" height="auto" 
                      controls autoplay>
                      <source src="https://res.cloudinary.com/quecko123/video/upload/v1676284959/quecko/quick/sibb/Welcome_To_CUPID_AI_iqd0au.mp4" type="video/mp4" className="img-fluid" />
             
                    </video>
 */}
                    <video id="myVideo"
                      className=" banervideo"
                      muted="muted"
                      playsinline="playsinline"
                      autoPlay
                      loop
                      class="banervideo"
                      width="100%" height="auto"
                    >
                      <source
                        src="https://res.cloudinary.com/quecko123/video/upload/v1676284959/quecko/quick/sibb/Welcome_To_CUPID_AI_iqd0au.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2 m-auto">
                  <div className="lovessssssimg">
                    <img
                      src="\love-arrow-andbow.gif"
                      alt="img"
                      className="img-fluid header_imgsss "
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Secbannars />

    </>
  );
};

export default Banner;

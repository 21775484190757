import React, { useEffect } from "react";
import "./thrddsection.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Piechart from "../Piechat/Piechart";

function Thrddsection() {
    return (
        <section className="thrd_section" id="Thrddsection">
            <div className="banarbging">
                
        {/* <img
                      src="\thrddbgimg.svg"
                      alt="img"
                      className="img-fluid header_imgs "

                    /> */}
           
            <div className="container-fluid p-0 ">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 col-10  m-auto ">
                        <div className="tokenomicsthrdd">
                            <h1 className="tokenomics">
                                Cupid.Ai Tokenomics
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row mediaquery_tab">
                    <div className="col-xl-6 col-lg-12 col-12  m-auto p-0  padd-sm">
                        <div className="chartimg">
                            <Piechart />
                            {/* <h1> Donat chart</h1> */}
                        </div>
                        <div className="lastthrd_texxchart">
                            <h3 className="tokenomicsss_liquidity">
                                100%
                                Liquidity lock
                            </h3>
                            <h3 className="tokenomicsssndyears">
                                for 99 years
                            </h3>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-12  m-auto padd-sm">
                        <div className="outer_card">
                            <div className="inner_card">
                                <h3 className="tokenomicsss">
                                    Cupid.Ai Tokenomics
                                </h3>
                                <p className="revolutionary">
                                    Cupid.Ai is a revolutionary platform that aims to democratize the creation of decentralized applications (dApps) in the Web3 ecosystem. The platform is designed to be a powerful alternative to traditional website builders like Wix and WordPress, with a focus on providing users with easy access to cutting-edge technologies like smart contract generation and AI-powered art generation.
                                </p>
                                <ul class="thrdbaner_list">
                                    <li>Platform payments in CUPID.AI will have 0% fees</li>
                                    <li>CUPID.AI is an ERC-20 token</li>
                                    <li>CUPID.AI 100% Liquidity locked</li>
                                    <li>CUPID.AI will transition to Shibarium network (L2)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lastthrd_texx">
                            <h3 className="tokenomicsss">
                                0% TAX
                            </h3>
                            <h3 className="tokenomicsssnd">
                                No Transfer Tax
                            </h3>
                        </div>
                        <div className="lastthrd_texxOwnership">
                            <h3 className="Ownership">
                                Ownership
                                renounced
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Thrddsection
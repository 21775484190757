import React, { useEffect } from "react";
import "./secbannars.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Thrddsection from "../thrddsection/Thrddsection";

const Secbannars = () => {
    return (
        <section className="sec_section" id="secbannars">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-11 col-lg-12 col-12 p-0 m-auto padd-sm">
                        <div className="main_img">
                            <div className="sendhanding  " data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500" >
                                <h6 className="partners">
                                    Some of our trusted partners & Investors
                                </h6>
                            </div>
                            <div className="row">
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6  my-1 p-0-auto padd-sm" data-aos="fade-left" data-aos-easing="linear"
                                    data-aos-duration="3000">
                                    <img src="\Rotashow.svg" alt="img" className='img-fluid ' />
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6  my-1 p-0-auto padd-sm" data-aos="fade-left" data-aos-easing="linear"
                                    data-aos-duration="2500">
                                    <img src="\waves.svg" alt="img" className='img-fluid ' />
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6  my-1 p-0-auto padd-sm" data-aos="fade-left" data-aos-easing="linear"
                                    data-aos-duration="2000">
                                    <img src="\Rotashow.svg" alt="img" className='img-fluid ' />
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6  my-1 p-0-auto padd-sm" data-aos="fade-left" data-aos-easing="linear"
                                    data-aos-duration="1500">
                                    <img src="\Travelers logo.svg" alt="img" className='img-fluid ' />
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6  my-1 p-0-auto padd-sm" data-aos="fade-left" data-aos-easing="linear"
                                    data-aos-duration="1000">
                                    <img src="\Goldlines.svg" alt="img" className='img-fluid ' />
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6  my-1 p-0-auto padd-sm" data-aos="fade-left" data-aos-easing="linear"
                                    data-aos-duration="500">
                                    <img src="\llllogo.svg" alt="img" className='img-fluid ' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider_maindiv">
                    <div className="row">
                        <div className="col-xl-6 col-12 ">
                            <div className="carouselll " data-aos="zoom-in" data-aos-easing="linear"
                                data-aos-duration="1500">
                                <div class="carousel">
                                    <ul class="slides">
                                        <input type="radio" name="radio-buttons" id="img-1" checked />
                                        <li class="slide-container" data-interval="1000">
                                            <div class="slide-image">
                                                <img src="\secsliderimg.svg" />
                                            </div>
                                        </li>
                                        <input type="radio" name="radio-buttons" id="img-2" />
                                        <li class="slide-container">
                                            <div class="slide-image">
                                                <img src="\secsliderimg.svg" />
                                            </div>
                                        </li>
                                        <input type="radio" name="radio-buttons" id="img-3" />
                                        <li class="slide-container">
                                            <div class="slide-image">
                                                <img src="\secsliderimg.svg" />
                                            </div>
                                        </li>
                                        <div class="carousel-dots">
                                            <label for="img-1" class="carousel-dot" id="img-dot-1"></label>
                                            <label for="img-2" class="carousel-dot" id="img-dot-2"></label>
                                            <label for="img-3" class="carousel-dot" id="img-dot-3"></label>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-12 ">
                            <div className="" data-aos="zoom-in" data-aos-easing="linear"
                                data-aos-duration="1500">
                                <button className="ccupid">About Cupid</button>
                                <h4 className="whatisupid">
                                    What is Cupid.Ai?
                                </h4>
                                <p className="revolutionary">
                                    Cupid.Ai is a revolutionary platform that aims to democratize the creation of decentralized applications (dApps) in the Web3 ecosystem. The platform is designed to be a powerful alternative to traditional website builders like Wix and WordPress, with a focus on providing users with easy access to cutting-edge technologies like smart contract generation and AI-powered art generation.
                                </p>
                                <button className="buyyy">
                                    Buy $CUPID
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-10 col-lg-12 col-12  m-auto padd-sm">
                        <div className="commingsoom  animate__animated animate__backInDown" >
                            <h2 className="dating">
                                1st dating dapp in crypto <span className="comingsoon. "
                                 data-aos="fade-up"
                                    data-aos-anchor-placement="top-center" >
                                    coming soon.
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <Thrddsection />
        </section>

    );
}

export default Secbannars;

import { useEffect } from 'react';
import "./getinvolved.scss";

import AOS from "aos";
import "aos/dist/aos.css";
export const Getinvolved = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>

      <section className="secondlast">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-12 col-11  p-0 m-auto padd-sm">
              <div className="main-bodyyy p-0" style={{ overflow: "hidden" }}>
                <div className="row">
                  <div className="col-xl-6 col-12  m-auto">
                    <div className="parent">
                      <h2>Ready to see Web3 innovation? </h2>
                      <p className="">
                        Cupid.Ai is a revolutionary platform that aims to democratize the creation of decentralized applications (dApps) in the Web3 ecosystem.
                      </p>
                      <a href="https://t.me/cupid_AI" target="_blank">

                        <button className="transparent-btn" data-aos="fade-up" data-aos-duration="3000">
                          Join our Telegram


                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6  col-12 m-auto padd-sm">
                    <div className="parentss"  >
                      <div className="desktop-img " >
                        {/* <img src="/join-us-shadow.png" alt="img" className="join-shadow" /> */}
                        <img src="/Cupid_AI_Alpha 2.svg" alt="img" className="img-fluid desktopimggg" />
                      </div>
                      <div className="mobile-img text-center d-none">
                        {/* <img src="\mobile-join-shadow.png" alt="img" className="join-shadow" /> */}
                        {/* <img src="\Cupid_AI_Alpha 2.svg" alt="img" className="img-fluid " /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};
export default Getinvolved;
